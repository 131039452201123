@tailwind base;
@tailwind components;
@tailwind utilities;

body,html{
  font-family: 'Poppins', sans-serif;
  scroll-behavior: smooth;
}

::selection{
  background: gray;
}
.icon{
  width: 100px;
  height: 100px;
}
@media only screen and (min-width: 1536px) {
  .icon {
    width: 200px;
    height: 200px;
  }
}